import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Blog" />
    <div className="flex flex-col flex-wrap justify-center space-y-4 text-center">
      <h1 className="text-3xl font-bold md:text-4xl text-primary dark:text-secondary">
        Oups,
        <br />
        Désolé.
      </h1>
      <h3 className="mt-0 mb-2 text-2xl font-normal leading-normal">
        Vous voyagez sur un chemin qui n'existe pas encore !
      </h3>
      <Link className="font-bold hover:text-purple-500" to="/">
        Rentrons, nous essaierons plus tard
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
